@import '../../css/index-variables';
@import '../../css/fonts/shanticon/style.css';

// Site Banner
.c-site__header {
    padding: 0;
    background: #fff;
    display: flex;
    height: 4.5rem;

    .siteSettings-Toggle--button {
        background-color: transparent;
        border: none;
        width: 5rem;
        border-right: 1px solid #ccc;
        border-radius: 0;
        justify-content: space-around;

        &:hover,
        &:active,
        &:focus {
            background-color: transparent;
            border-color: #ccc;
            box-shadow: none;
        }

        svg {
            color: #555;
            font-size: 2rem;
        }
    }

    .navbar-brand {
        display: inline-block;
        border-bottom: none;

        h1 {
            /* font-size: 1.5rem;
            display: inline;
            margin: 0 0 0 2rem;
            padding: 0;
            line-height: 1.5; */
        }

        img {
            /*position: relative;
            top: -0.2rem;*/
        }
    }

    & > nav {
        flex: 1 1;
        background-color: #fff !important;
        padding: 0;
    }

    // --- Search Box ----
    .c-site__search {
        margin-top: 0.25rem;
        padding-left: 0.5rem;
    }
}

.sui-search1 {
    position: relative;
    top: 0.5rem;

    input.sui-search2 {
        font-size: 1.65rem;
        padding: 0.4rem 1rem 0.25rem 1rem;
        background: transparent;
        border: none;
        border-bottom: 0.1rem solid rgba(77, 89, 201, 0.8); // --- color mandala, w/ opacity
        margin: 0;
        width: 27rem;
        transition: width 0.5s, border 0.35s;
        color: #000;
        box-shadow: none;
        background: transparent;

        &:placeholder-shown {
            cursor: text;
        }
        &:focus,
        &:active {
            padding-right: 4.75rem;
            width: 34rem;
            outline: none;
            background: #fff;
            border-bottom-width: 0.25rem;
            border-bottom-color: $color-mandala;
            cursor: text;

            &::-webkit-input-placeholder {
                color: transparent;
            }
            &::-moz-placeholder {
                color: transparent;
                cursor: text;
            }
            &:-ms-input-placeholder {
                color: transparent;
            }
            &:-moz-placeholder {
                color: transparent;
            }
        }
        &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: $color-mandala;
            font-style: italic;
            font-size: 2.25rem;
            font-family: 'GB Garamond', serif;
            letter-spacing: 0.0125rem;
        }
        &::-moz-placeholder {
            /* Firefox 19+ */
            color: $color-mandala;
            opacity: 1;
            font-style: italic;
        }
        &:-ms-input-placeholder {
            /* IE 10+ */
            color: $color-mandala;
            font-style: italic;
        }
        &:-moz-placeholder {
            /* Firefox 18- */
            color: $color-mandala;
            opacity: 1;
            font-style: italic;
        }
    }
    .sui-search3 {
        position: relative;
        right: 3rem;
        top: 0.35rem;
        display: inline-block;
        height: 2.5rem;
        width: 2.5rem;
        transition: color 0.75s;
    }
    .sui-search4 {
        // --- search enter - Go get it!
        // position: absolute;
        // top: 0.025rem;
        // right: 3.15rem;
        display: none;
        // font-size: 1.95rem;
        span {
            &::before {
                color: $color-mandala;
                opacity: 0.6;
                transition: opacity 400ms;
            }
            &:hover::before {
                opacity: 1;
                color: #000;
            }
        }
    }
}

input.sui-search2:focus + .sui-search3,
input.sui-search2:active + .sui-search3 {
    &::before {
        content: 'X';
        display: inline-block;
        color: #aaa;
        font-size: 1.75rem;
        position: absolute;
        top: 0;
        right: 1.6rem;
    }
    &:hover::before {
        color: $color-subjects;
    }
}

input.sui-search2:focus + .sui-search3 + .sui-search4 span::before,
input.sui-search2:active + .sui-search3 + .sui-search4 span::before {
    opacity: 1;
}
input.sui-search2:focus + .sui-search3 + .sui-search4 span:hover::before,
input.sui-search2:active + .sui-search3 + .sui-search4 span:hover::before {
    color: #000;
}
.navbar-nav,
.navbar-toggler,
.c-site__header-standalone {
    display: none;
}

// --------------------------------------------------------------------------------
// TOP BANNER ---- Headroom React show-hide sticky-header ------ script/css testing
// -------------------------------------------------------------------------------
html,
body {
    /* scroll-padding-top: 4rem; set to the height of header */
}
.headroom {
    top: 0;
    left: 0;
    right: 0;
    z-index: 2000;
    box-shadow: rgba(0, 0, 0, 0.15) 1px 1px 1px;
    will-change: transform;
    transition: transform 350ms ease-in-out;
}
.headroom--scrolled {
}
.headroom--unpinned {
    position: fixed;
    transform: translateY(-100%);
    //   display: none;
    visibility: hidden;
}
.headroom--pinned {
    position: fixed;
    transform: translateY(0);
    display: block;
}
.headroom--top {
}
.headroom--top,
.headroom--unfixed {
    position: relative;
    transform: translateY(0);
}
// --------------------------------------
// END of Headroom script/css testing
// ----------------------
