@import '../../css/index-variables';

button.mdl-login {
    border: none;
    background: transparent;
    padding: 0 1.5rem 0.5rem 0;
    font-size: 20px;
}

.c-MainSearchToggle--group {
    .c-MainSearchToggle--button {
        background-color: #fff;
        padding: 0.8rem 0;
        width: 6.5rem;
        height: 4.7rem;
        color: black;
        border-color: #ddd !important;
        border-bottom-color: transparent !important;
        opacity: 1;
        transition: all 0.35s;
        &.focus,
        &.active,
        &.active:hover,
        &:focus {
            background-color: #3f49b1 !important;
            opacity: 1;
            // box-shadow: inset 0 0 2.5rem #3f49b1 !important;
        }
        &:not(.active) {
            &:first-child {
                border-left: 0.15rem solid #ccc !important;
            }
        }
        svg {
            font-size: 2.2rem;
            color: #000;
            position: relative;
            top: 0.25rem;
        }
        &.active svg {
            color: #fff;
        }
        input:focus {
            box-shadow: none !important;
            outline: none !important;
            border-radius: 0;
            border-width: 0 0.15rem;
        }
        .icon {
            color: #333;
            font-size: 2.85rem;
            position: relative;
            bottom: 0.5rem;
        }
        &.active .icon {
            color: #fff;
        }
        &.active {
            background-color: #4d59ca;
            &::before {
                width: 0;
                height: 0;
                content: '';
                display: inline-block;
                border-style: solid;
                border-width: 1px 6px 7px 6px;
                border-color: transparent transparent #fff transparent;
                position: absolute;
                bottom: -0.3rem;
                left: 50%;
                z-index: 1005;
                margin-left: -0.5rem;
                background-color: transparent;
            }
        }
        &.btn:not(:disabled):not(.disabled) {
            cursor: default;
        }
    }
}
